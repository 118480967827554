import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Gallery from '../components/Gallery';
import PerfectScrollbar from 'react-perfect-scrollbar';

import style from '../assets/style/pages/cooperation.module.scss';

export default ({ data }) => {

  const images = data.images.edges.map(({ node }) => node);

  return (
    <Layout>
      <SEO title="Współpraca" />

      <section className={style.cooperationSection}>
        <div className="container">
          <div className={`row ${style.cooperationSection__row}`}>

            <div className="col-12 col-lg-6 offset-xl-1 col-xxl-5">
              <h1>Z kim współpracujemy</h1>
              <PerfectScrollbar className={style.scrollArea1} horizontal={false}>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore rem voluptas magni quasi consequuntur aliquam cumque autem vero rerum architecto, aut aliquid veniam officia doloribus optio eveniet neque! Laboriosam, maxime! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Hic dignissimos sunt praesentium sint sapiente totam, ducimus numquam sequi quia magni! Natus dolor dicta id nisi sunt delectus at blanditiis repudiandae.
                </p>
              </PerfectScrollbar>
            </div>

            <div className="col-12 col-lg-6 col-xl-5 offset-xxl-1 ">
              <PerfectScrollbar className={style.scrollArea2} horizontal={false}>
                <Gallery images={images} />
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
};

export const query = graphql`
  query {
    images: allFile(filter: { relativeDirectory: { eq: "assets/images/gallery" } }) {
      edges {
        node {
          id
          src: publicURL
          childImageSharp {
            fixed(width: 290, height: 290) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
