import styled from "@emotion/styled"

export const StyledGallery = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: -10px;

  .gallery-item {
    width: 290px;
    height: 290px;
    margin: 10px;
    transition: opacity .3s;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      opacity: .75;
    }

    figure {
      margin: 0;

      img {
        margin: 0;
      }
    }

    @media (min-width: 576px) {
      width: 245px;
      height: 245px;
    }

    @media (min-width: 768px) {
      width: 216px;
      height: 216px;
    }

    @media (min-width: 992px) {
      width: 215px;
      height: 215px;
    }

    @media (min-width: 1200px) {
      width: 212px;
      height: 212px;
    }

    @media (min-width: 1440px) {
      width: 258px;
      height: 258px;
    }

    @media (min-width: 1580px) {
      width: 293px;
      height: 293px;
    }
  }
`
