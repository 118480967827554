import React from 'react'
import Img from 'gatsby-image'
import Carousel, { Modal, ModalGateway } from 'react-images';
import { StyledGallery } from './Gallery.styled'

const Gallery = ({ images }) => {

  const lightboxImages = images.map(({ src }) => ({ src }));

  const [open, setOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState(0);

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = (e) => {
    setOpen(true);
    setCurrentImage(parseInt(e.currentTarget.getAttribute('data-index')));
  };

  const handleKeyPress = (e) => {
    openModal(e);
  }

  return (
    <>
      <StyledGallery>
        {
          images.map(({ id, childImageSharp }, i) => (
            <div key={id} className="gallery-item" data-index={i} role="button" tabIndex="0" onClick={openModal} onKeyPress={handleKeyPress}>
              <figure>
                <Img fixed={childImageSharp.fixed} alt="Zdjęcie realizacji" style={{ display: 'block', width: '100%', height: '0', paddingBottom: '100%' }} />
              </figure>
            </div>
          ))
        }
      </StyledGallery>

      <ModalGateway>
        {open ? (
          <Modal onClose={closeModal}>
            <Carousel currentIndex={currentImage} views={lightboxImages} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

export default Gallery;
